/* tslint:disable */
/* eslint-disable */
/**
 * Dataland Backend API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../../../../../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../../../../../../base';
// @ts-ignore
import type { CompanyAssociatedDataEuTaxonomyDataForFinancials } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { DataAndMetaInformationEuTaxonomyDataForFinancials } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { DataMetaInformation } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { ErrorResponse } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
/**
 * EuTaxonomyDataForFinancialsControllerApi - axios parameter creator
 * @export
 */
export const EuTaxonomyDataForFinancialsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {boolean} [showOnlyActive] 
         * @param {string} [reportingPeriod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyEuTaxonomyDataForFinancials: async (companyId: string, showOnlyActive?: boolean, reportingPeriod?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAllCompanyEuTaxonomyDataForFinancials', 'companyId', companyId)
            const localVarPath = `/data/eutaxonomy-financials/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)

            if (showOnlyActive !== undefined) {
                localVarQueryParameter['showOnlyActive'] = showOnlyActive;
            }

            if (reportingPeriod !== undefined) {
                localVarQueryParameter['reportingPeriod'] = reportingPeriod;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyAssociatedEuTaxonomyDataForFinancials: async (dataId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataId' is not null or undefined
            assertParamExists('getCompanyAssociatedEuTaxonomyDataForFinancials', 'dataId', dataId)
            const localVarPath = `/data/eutaxonomy-financials/{dataId}`
                .replace(`{${"dataId"}}`, encodeURIComponent(String(dataId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyAssociatedDataEuTaxonomyDataForFinancials} companyAssociatedDataEuTaxonomyDataForFinancials 
         * @param {boolean} [bypassQa] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyAssociatedEuTaxonomyDataForFinancials: async (companyAssociatedDataEuTaxonomyDataForFinancials: CompanyAssociatedDataEuTaxonomyDataForFinancials, bypassQa?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyAssociatedDataEuTaxonomyDataForFinancials' is not null or undefined
            assertParamExists('postCompanyAssociatedEuTaxonomyDataForFinancials', 'companyAssociatedDataEuTaxonomyDataForFinancials', companyAssociatedDataEuTaxonomyDataForFinancials)
            const localVarPath = `/data/eutaxonomy-financials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)

            if (bypassQa !== undefined) {
                localVarQueryParameter['bypassQa'] = bypassQa;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyAssociatedDataEuTaxonomyDataForFinancials, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EuTaxonomyDataForFinancialsControllerApi - functional programming interface
 * @export
 */
export const EuTaxonomyDataForFinancialsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EuTaxonomyDataForFinancialsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {boolean} [showOnlyActive] 
         * @param {string} [reportingPeriod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCompanyEuTaxonomyDataForFinancials(companyId: string, showOnlyActive?: boolean, reportingPeriod?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataAndMetaInformationEuTaxonomyDataForFinancials>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCompanyEuTaxonomyDataForFinancials(companyId, showOnlyActive, reportingPeriod, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EuTaxonomyDataForFinancialsControllerApi.getAllCompanyEuTaxonomyDataForFinancials']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} dataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyAssociatedEuTaxonomyDataForFinancials(dataId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyAssociatedDataEuTaxonomyDataForFinancials>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyAssociatedEuTaxonomyDataForFinancials(dataId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EuTaxonomyDataForFinancialsControllerApi.getCompanyAssociatedEuTaxonomyDataForFinancials']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CompanyAssociatedDataEuTaxonomyDataForFinancials} companyAssociatedDataEuTaxonomyDataForFinancials 
         * @param {boolean} [bypassQa] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompanyAssociatedEuTaxonomyDataForFinancials(companyAssociatedDataEuTaxonomyDataForFinancials: CompanyAssociatedDataEuTaxonomyDataForFinancials, bypassQa?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataMetaInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompanyAssociatedEuTaxonomyDataForFinancials(companyAssociatedDataEuTaxonomyDataForFinancials, bypassQa, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EuTaxonomyDataForFinancialsControllerApi.postCompanyAssociatedEuTaxonomyDataForFinancials']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EuTaxonomyDataForFinancialsControllerApi - factory interface
 * @export
 */
export const EuTaxonomyDataForFinancialsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EuTaxonomyDataForFinancialsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {boolean} [showOnlyActive] 
         * @param {string} [reportingPeriod] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyEuTaxonomyDataForFinancials(companyId: string, showOnlyActive?: boolean, reportingPeriod?: string, options?: any): AxiosPromise<Array<DataAndMetaInformationEuTaxonomyDataForFinancials>> {
            return localVarFp.getAllCompanyEuTaxonomyDataForFinancials(companyId, showOnlyActive, reportingPeriod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyAssociatedEuTaxonomyDataForFinancials(dataId: string, options?: any): AxiosPromise<CompanyAssociatedDataEuTaxonomyDataForFinancials> {
            return localVarFp.getCompanyAssociatedEuTaxonomyDataForFinancials(dataId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyAssociatedDataEuTaxonomyDataForFinancials} companyAssociatedDataEuTaxonomyDataForFinancials 
         * @param {boolean} [bypassQa] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyAssociatedEuTaxonomyDataForFinancials(companyAssociatedDataEuTaxonomyDataForFinancials: CompanyAssociatedDataEuTaxonomyDataForFinancials, bypassQa?: boolean, options?: any): AxiosPromise<DataMetaInformation> {
            return localVarFp.postCompanyAssociatedEuTaxonomyDataForFinancials(companyAssociatedDataEuTaxonomyDataForFinancials, bypassQa, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EuTaxonomyDataForFinancialsControllerApi - interface
 * @export
 * @interface EuTaxonomyDataForFinancialsControllerApi
 */
export interface EuTaxonomyDataForFinancialsControllerApiInterface {
    /**
     * 
     * @param {string} companyId 
     * @param {boolean} [showOnlyActive] 
     * @param {string} [reportingPeriod] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EuTaxonomyDataForFinancialsControllerApiInterface
     */
    getAllCompanyEuTaxonomyDataForFinancials(companyId: string, showOnlyActive?: boolean, reportingPeriod?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DataAndMetaInformationEuTaxonomyDataForFinancials>>;

    /**
     * 
     * @param {string} dataId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EuTaxonomyDataForFinancialsControllerApiInterface
     */
    getCompanyAssociatedEuTaxonomyDataForFinancials(dataId: string, options?: RawAxiosRequestConfig): AxiosPromise<CompanyAssociatedDataEuTaxonomyDataForFinancials>;

    /**
     * 
     * @param {CompanyAssociatedDataEuTaxonomyDataForFinancials} companyAssociatedDataEuTaxonomyDataForFinancials 
     * @param {boolean} [bypassQa] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EuTaxonomyDataForFinancialsControllerApiInterface
     */
    postCompanyAssociatedEuTaxonomyDataForFinancials(companyAssociatedDataEuTaxonomyDataForFinancials: CompanyAssociatedDataEuTaxonomyDataForFinancials, bypassQa?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<DataMetaInformation>;

}

/**
 * EuTaxonomyDataForFinancialsControllerApi - object-oriented interface
 * @export
 * @class EuTaxonomyDataForFinancialsControllerApi
 * @extends {BaseAPI}
 */
export class EuTaxonomyDataForFinancialsControllerApi extends BaseAPI implements EuTaxonomyDataForFinancialsControllerApiInterface {
    /**
     * 
     * @param {string} companyId 
     * @param {boolean} [showOnlyActive] 
     * @param {string} [reportingPeriod] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EuTaxonomyDataForFinancialsControllerApi
     */
    public getAllCompanyEuTaxonomyDataForFinancials(companyId: string, showOnlyActive?: boolean, reportingPeriod?: string, options?: RawAxiosRequestConfig) {
        return EuTaxonomyDataForFinancialsControllerApiFp(this.configuration).getAllCompanyEuTaxonomyDataForFinancials(companyId, showOnlyActive, reportingPeriod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EuTaxonomyDataForFinancialsControllerApi
     */
    public getCompanyAssociatedEuTaxonomyDataForFinancials(dataId: string, options?: RawAxiosRequestConfig) {
        return EuTaxonomyDataForFinancialsControllerApiFp(this.configuration).getCompanyAssociatedEuTaxonomyDataForFinancials(dataId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyAssociatedDataEuTaxonomyDataForFinancials} companyAssociatedDataEuTaxonomyDataForFinancials 
     * @param {boolean} [bypassQa] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EuTaxonomyDataForFinancialsControllerApi
     */
    public postCompanyAssociatedEuTaxonomyDataForFinancials(companyAssociatedDataEuTaxonomyDataForFinancials: CompanyAssociatedDataEuTaxonomyDataForFinancials, bypassQa?: boolean, options?: RawAxiosRequestConfig) {
        return EuTaxonomyDataForFinancialsControllerApiFp(this.configuration).postCompanyAssociatedEuTaxonomyDataForFinancials(companyAssociatedDataEuTaxonomyDataForFinancials, bypassQa, options).then((request) => request(this.axios, this.basePath));
    }
}

